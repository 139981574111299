import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useSnackbar } from "notistack";
import { Box, Chip, Grid, IconButton, Tooltip } from "@mui/material";
import {
  Refresh as RefreshIcon,
  FileDownload as FileDownloadIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

import {
  generateWfPdfReportbyId,
  getAllClientProducerFarms,
  getAllClientProducers,
  getAllClientsNoPagination,
  getClientIdByToken,
  useWorkflowContainers,
  useWorkflowContainerThree,
  validTaskCodeByToken,
  workflowContainerCheckPointDelete,
} from "../../../../../libs/apis";
import {
  ClientI,
  ClientProducerFarmI,
  ClientProducerI,
  WorkflowContainerI,
} from "../../../../../libs/interfaces";
import { HEIGHT_TABLE_CONTAINER } from "../../../../../libs/constants";
import {
  ExcelComponent,
  PdfElement,
  SendReport,
} from "../../../../../libs/shared";
import { useWidth } from "../../../../../libs/hooks";
import { useNavigate } from "react-router-dom";
import "../../../../../libs/css/list.css";
import {
  Button as ButtonAntd,
  Tooltip as TooltipAntd,
  Popconfirm,
  message,
  Select,
} from "antd";
import {
  DeleteFilled,
  EditOutlined,
  FilePdfOutlined,
  GlobalOutlined,
  SendOutlined,
  VideoCameraTwoTone,
} from "@ant-design/icons";
import { Environments } from "../../../../../libs/enums";

const titleExport = "ContenedorFull";
const routeEdit = "/container/full/edit/";

export const TableComponent = () => {
  const width = useWidth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [messageApi, contextHolder] = message.useMessage();
  const [sorting, setSorting] = useState([{ id: "id", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: width === "ab" ? 10 : width === "xl" ? 10 : 10,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [producerName, setProducerName] = useState<any>(null);
  const [producer, setProducer] = useState<any>(null);
  const [producers, setProducers] = useState<ClientProducerI[]>([]);
  const [farmName, setFarmName] = useState<any>(null);
  const [farm, setFarm] = useState<any>(null);
  const [farms, setFarms] = useState<ClientProducerFarmI[]>([]);
  const [clientName, setClientName] = useState<any>(null);
  const [client, setClient] = useState<any>(null);
  const [clients, setClients] = useState<ClientI[]>([]);
  const { workflowContainerThreeQuery } = useWorkflowContainerThree({
    sorting,
    pagination,
    globalFilter,
    client,
    producer,
    farm,
  });
  const { data, isLoading, refetch, isRefetching } = workflowContainerThreeQuery;
  const [rowSelection, setRowSelection] = useState({});

  const [isDeleting, setDeleting] = useState(false);
  const [getExportData, setExportData] = useState([]);
  const [getExportHeader, setExportHeader] = useState([]);
  const [isContinuoToExport, setContinuoToExport] = useState(false);
  const [isReadyExport, setReadyExport] = useState(false);
  const [isGeneratingPdf, setGeneratingPdf] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [continuoSend, setContinuoSend] = useState(false);
  const [visibleSend, setVisibleSend] = useState(false);
  const [dataShow, setDataShow] = useState<any>({});
  const [rowId, setRowId] = useState<number | null>(null);
  const [elContainer, setContainer] = useState("");
  const [continuoModel, setContinuoModel] = useState(false);
  const [openModel, setModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showExporters, setShowExporters] = useState(false);

  useEffect(() => {
    handleGetData();
    return () => {
      setShowExporters(false);
    };
  }, []);

  useEffect(() => {
    if (isContinuoToExport) {
      setContinuoToExport(false);
      setReadyExport(true);
    }
  }, [isContinuoToExport]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (continuoSend) {
      setContinuoSend(false);
      setVisibleSend(true);
      setOpenSend(true);
    }
  }, [continuoSend]);

  useEffect(() => {
    if (continuoModel) {
      setContinuoModel(false);
      setModalOpen(true);
    }
  }, [continuoModel]);

  const handleGetData = async () => {
    const { data: dataClient } = await getClientIdByToken();
    if (dataClient) {
      setClient(dataClient);
      getProducers(dataClient);
    }

    const { data: dataTask } = await validTaskCodeByToken(
      Environments.TASK_CONTAINER_EMPTY_ASSIGN
    );

    if (!dataTask) return;
    if (dataTask.status) {
      setShowExporters(true);
      const { data: dataClients } = await getAllClientsNoPagination();
      if (!dataClients) return;
      setClients(dataClients);
    }
  };

  const getFarms = useCallback(async (clientProducer: number | null) => {
    try {
      if (clientProducer === null) {
        setFarms([]);
        return;
      }

      const { data: dataFarms } = await getAllClientProducerFarms(
        clientProducer
      );
      if (!dataFarms) return;
      setFarms(dataFarms);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProducers = useCallback(async (clientId: number | null) => {
    try {
      if (clientId === null) {
        setProducers([]);
        return;
      }

      const { data: dataProducers } = await getAllClientProducers(clientId);
      if (!dataProducers) return;
      setProducers(dataProducers);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const columns = useMemo<MRT_ColumnDef<WorkflowContainerI>[]>(
    () => [
      {
        accessorKey: "container",
        id: "container",
        header: "Contenedor",
        minSize: 100,
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <a className="b-proform" onClick={() => handlePdf(row.original)}>
              {renderedCellValue}
            </a>
          </Box>
        ),
      },
      {
        accessorKey: "userCreation",
        id: "userCreation",
        header: "Usuario Creación",
        minSize: 100,
        size: 200,
      },
      {
        accessorKey: "processType",
        id: "processType",
        header: "Tipo Proceso",
        minSize: 150,
        size: 150,
      },
      {
        accessorKey: "hour",
        id: "hour",
        header: "Hora",
        minSize: 150,
        size: 150,
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: "Creacion",
        minSize: 150,
        size: 150,
      },
    ],
    []
  );

  const handleDeleteRow = useCallback(async (row: WorkflowContainerI) => {
    setDeleting(true);
    try {
      const { data: dataWorkflow } = await workflowContainerCheckPointDelete(
        row.id!
      );
      enqueueSnackbar(dataWorkflow, { variant: "success" });
      refetch();
      setDeleting(false);
    } catch (err) {
      enqueueSnackbar((err as Error).message, { variant: "error" });
      setDeleting(false);
    }
  }, []);

  const handleExportRows = (rows: any, columnsShow: any) => {
    const headersActive = columnsShow
      .slice(2)
      .filter((el: any) => el.getIsVisible())
      .map((el: any) => ({
        label: el.columnDef.header,
        id: el.columnDef.id,
        width: el.columnDef.size,
        textAlign: "left",
        show: true,
        fontSize: "10px",
      }));

    rows = rows.map((el: any) => el.original);

    setExportData(rows);
    setExportHeader(headersActive);
    setContinuoToExport(true);
  };

  const handleClickPdf = () => {
    setTimeout(() => {
      setReadyExport(false);
      setExportData([]);
      setExportHeader([]);
    }, 1500);
  };

  const handleResetExport = () => {
    setReadyExport(false);
    setExportData([]);
    setExportHeader([]);
  };

  const handleNavigate = useCallback((row: WorkflowContainerI) => {
    const routePath = routeEdit + row.id;
    navigate(routePath);
  }, []);

  const handlePdf = async (row: WorkflowContainerI) => {
    try {
      setGeneratingPdf(true);

      messageApi.open({
        key: "generatePdfKey1",
        type: "loading",
        content: "Generando Pdf",
        duration: 50,
      });
      const { data: dataPdf } = await generateWfPdfReportbyId(row.id!);
      messageApi.destroy();
      messageApi.open({
        key: "generatePdfKey4",
        type: "loading",
        content: "Procesando Pdf",
        duration: 2,
        onClose: () => {
          message.success("PDF Descargado", 1);
        },
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/pdf;base64,${dataPdf}`;
      downloadLink.download = `report-${row.container}.pdf`;
      downloadLink.click();
      setGeneratingPdf(false);
    } catch (err: any) {
      messageApi.destroy();
      message.error(`Error al generar el pdf: ${err.toString()}`);
      setGeneratingPdf(false);
    }
  };

  const handleCloseSend = () => {
    setDataShow({});
    setOpenSend(false);
    setVisibleSend(false);
  };


  return (
    <Fragment>
      {contextHolder}
      <MaterialReactTable
        enableRowActions={false}
        //OPTIONS
        enableStickyHeader
        enableStickyFooter
        enableDensityToggle
        enableColumnOrdering
        enableColumnResizing
        enableEditing={false}
        enableGlobalFilter
        enableRowSelection
        manualFiltering
        manualPagination
        manualSorting
        muiTableContainerProps={({ table }) => {
          return {
            sx: {
              minHeight: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
              maxHeight: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
              height: table.options.state.isFullScreen
                ? `calc(100vh - 100px)`
                : `calc(100vh - 400px)`,
            },
          };
        }}
        columns={columns}
        data={data?.data?.dato ?? []}
        initialState={{
          density: "compact",
          rowSelection,
          sorting,
        }}
        state={{
          isLoading: isLoading || isRefetching,
          globalFilter,
          rowSelection,
          pagination,
          sorting,
        }}
        rowCount={data?.data?.total ?? 0}
        onRowSelectionChange={setRowSelection}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        renderTopToolbarCustomActions={({ table }) => (
          <Grid
            alignItems="center"
            container
            justifyContent="flex-start"
            spacing={1}
            sx={{ width: "auto" }}
          >
            <Grid item>
              <Tooltip title="Refrescar">
                <span>
                  <IconButton onClick={() => refetch()} color="warning">
                    <RefreshIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* {showExporters ? (
              <Grid item>
                <Select
                  showSearch
                  allowClear
                  style={{ width: 250 }}
                  placeholder="Exportador"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={clients.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                  value={clientName}
                  onChange={(value) => {
                    setClientName(value);
                    const findProducer = clients.find(
                      (el) => el.name === value
                    );
                    if (findProducer) {
                      setClient(findProducer.id);
                      getProducers(findProducer.id!);
                      setFarms([]);
                    } else {
                      setClient(null);
                      setProducers([]);
                      setFarms([]);
                    }
                  }}
                />
              </Grid>
            ) : null} */}
            <Grid item sx={{ display: "grid", placeContent: "center" }}>
              <Tooltip arrow title="Export Data">
                <span>
                  <IconButton
                    disabled={
                      (!table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()) ||
                      isReadyExport
                    }
                    onClick={() =>
                      handleExportRows(
                        table.getSelectedRowModel().rows,
                        table.getAllColumns()
                      )
                    }
                    color="success"
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item sx={{ display: "grid", placeContent: "center" }}>
              {isReadyExport ? (
                <PdfElement
                  data={getExportData}
                  headersColumn={getExportHeader}
                  HandleClickPdf={handleClickPdf}
                  isSmall={true}
                />
              ) : null}
            </Grid>
            <Grid item sx={{ display: "grid", placeContent: "center" }}>
              {isReadyExport ? (
                <ExcelComponent
                  data={getExportData}
                  titulo={titleExport}
                  headersColumn={getExportHeader}
                  isEcuafact={false}
                />
              ) : null}
            </Grid>
            <Grid item sx={{ display: "grid", placeContent: "center" }}>
              {isReadyExport ? (
                <Tooltip arrow title="Cancelar Exportación" placement="top">
                  <IconButton
                    onClick={handleResetExport}
                    style={{ backgroundColor: "#ECF0F1 ", color: "red" }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
        )}
        // renderRowActions={({ row, table }) => (
        //   <Box sx={{ display: "flex", gap: "0.4rem" }}>
        //     <TooltipAntd arrow={false} title="Editar">
        //       <ButtonAntd
        //         type="dashed"
        //         size="small"
        //         shape="circle"
        //         onClick={() => handleNavigate(row.original)}
        //         icon={<EditOutlined />}
        //       />
        //     </TooltipAntd>
        //     <TooltipAntd arrow={false} title="Eliminar">
        //       <Popconfirm
        //         title="Eliminar Contenedor Full"
        //         description="Seguro desea eliminar este Contenedor Full?"
        //         onConfirm={() => handleDeleteRow(row.original)}
        //         okText="Si"
        //         cancelText="No"
        //       >
        //         <ButtonAntd
        //           type="dashed"
        //           danger
        //           loading={isDeleting}
        //           disabled={isDeleting}
        //           size="small"
        //           shape="circle"
        //           icon={<DeleteFilled />}
        //         />
        //       </Popconfirm>
        //     </TooltipAntd>
        //     <TooltipAntd arrow={false} title="Pdf">
        //       <ButtonAntd
        //         loading={isGeneratingPdf}
        //         disabled={isGeneratingPdf}
        //         size="small"
        //         type="dashed"
        //         onClick={() => handlePdf(row.original)}
        //         icon={<FilePdfOutlined />}
        //       />
        //     </TooltipAntd>
        //     <TooltipAntd arrow={false} title="Enviar">
        //       <ButtonAntd
        //         type="dashed"
        //         size="small"
        //         onClick={() => handleOpenSend(row.original)}
        //         icon={<SendOutlined />}
        //       />
        //     </TooltipAntd>
        //     <TooltipAntd
        //       arrow={false}
        //       title="Aplicar Marca de Agua a los Videos"
        //     >
        //       <ButtonAntd
        //         disabled={row.original.statusVideo === "Realizado"}
        //         type="primary"
        //         size="small"
        //         onClick={() => handleWaterMark(row.original)}
        //         icon={<VideoCameraTwoTone twoToneColor="white" />}
        //       />
        //     </TooltipAntd>
        //     <TooltipAntd arrow={false} title="Link Externo">
        //       <ButtonAntd
        //         size="small"
        //         type="dashed"
        //         onClick={() => handleOpenExternal(row.original)}
        //         icon={<GlobalOutlined />}
        //       />
        //     </TooltipAntd>
        //   </Box>
        // )}
        localization={MRT_Localization_ES}
        // OPTIONS SELECTION ROW
        selectAllMode="all"
        positionToolbarAlertBanner="none"
      />
    </Fragment>
  );
};


