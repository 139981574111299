import { useQuery } from "@tanstack/react-query";
import { workflowContainerEmptyApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsWorkflowContainerEmptyI,
  WorkflowContainerEmptyI,
  ObjPostI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getWorkflowContainerEmptys = async ({
  pagination,
  sorting,
  globalFilter,
  producer,
  farm,
  client,
}: PropsWorkflowContainerEmptyI): Promise<
  ServiceResponseI<DataI<WorkflowContainerEmptyI>>
> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", client.toString());
    producer && params.append("producer", producer.toString());
    farm && params.append("farm", farm.toString());

    const { data } = await workflowContainerEmptyApi.get<
      ServiceResponseI<DataI<WorkflowContainerEmptyI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }

    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWorkflowContainerEmptys = async (): Promise<
  ServiceResponseI<WorkflowContainerEmptyI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerEmptyApi.get<
      ServiceResponseI<WorkflowContainerEmptyI[]>
    >(`/all-no-pagination`, { headers: { Authorization: validateToken } });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerEmptyPost = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.post<
      ServiceResponseI<ObjPostI>
    >("", getForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err: any) {
    if (err.code === "ERR_NETWORK") {
      return {
        data: {
          id: 0,
          alert: "Se guardará y notificará, cuando haya conexión a Internet",
          variant: "warning",
        },
        res: {} as any,
        req: {} as any,
      };
    }
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerEmptyPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.put<
      ServiceResponseI<string>
    >(`/${id}`, updateForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerEmptyById = async (
  id: number
): Promise<ServiceResponseI<WorkflowContainerEmptyI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.get<
      ServiceResponseI<WorkflowContainerEmptyI>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerEmptyCount = async (
  type="hoy"
): Promise<ServiceResponseI<{ total: number }>> => {
  try {
    const params = new URLSearchParams();

    type && params.append("type", type.toString());

    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.get<
      ServiceResponseI<{ total: number }>
    >(`/count`, { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerEmptyDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.delete<
      ServiceResponseI<string>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useWorkflowContainerEmptys = ({
  sorting,
  pagination,
  globalFilter,
  producer,
  farm,
  client,
}: PropsWorkflowContainerEmptyI) => {
  const workflowContainerEmptysQuery = useQuery(
    [
      "workflowContainerEmptys",
      { pagination, sorting, globalFilter, producer, farm, client },
    ],
    () =>
      getWorkflowContainerEmptys({
        pagination,
        sorting,
        globalFilter,
        producer,
        farm,
        client,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    workflowContainerEmptysQuery,
  };
};

export const generateWfEmptyPdfReportbyId = async (
  id: number
): Promise<ServiceResponseI<any>> => {
  try {
    // const validateToken = ValidateCurrentToken();
    // if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerEmptyApi.get<ServiceResponseI<any>>(
      `/generate-pdf-report/${id}`
      // { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowEmptySendReportById = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerEmptyApi.post<
      ServiceResponseI<string>
    >(`/send-report-by-workflow-id`, getForms, {
      headers: { Authorization: validateToken },
    });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};
