import React, { useRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog, Slide } from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useWidth } from "../../../../libs/hooks";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CameraComponentPropsI {
  open: boolean;
  handleClose: () => void;
  setQr: (fotoSiglas: string) => void;
}

export const ScanQrComponent = ({
  open,
  handleClose,
  setQr,
}: CameraComponentPropsI) => {
  const width = useWidth();
  const openId = useRef<number>(1);

  return (
    <Dialog
      keepMounted={false}
      key={openId.current}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        p: 0,
        m: 0,
        bgcolor: "#025875",
        background: "#025875",
        height: "100%",
        minHeight: "100%",
      }}
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1,
          background: "#025875",
          height: "100vh",
        }}
      >
        <Button
          style={{
            position: "absolute",
            top: 1,
            right: 1,
            zIndex: 2,
          }}
          size="large"
          onClick={handleClose}
          type="primary"
          // shape="circle"

          danger
          icon={<CloseCircleOutlined />}
        />
        <div
          style={{
            marginTop: width === "xs" ? "40%" : width === "sm" ? "5%" : "0%",
            width: width === "xs" ? "100%" : "50%",
            position: "relative",
            left: width === "xs" ? "0%" : "25%",
          }}
        >
          <Scanner
            constraints={{ aspectRatio: 1.8, frameRate:40, facingMode: "environment" }}
            onScan={(result) => {
              setQr(result[0].rawValue);
              handleClose();
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
