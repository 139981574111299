import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ListComponent = React.lazy(() => import("../../../../../components/container-empty/components/list/page"));
const AddComponent = React.lazy(() => import("../../../../../components/extra-container-empty-3/components/add/page"));
const VideosComponent = React.lazy(() => import("../../../../../components/container-empty/components/videos/page"));


export const extrasEmptyThreeRoute = [
    {
        path: 'list',
        element: <ListComponent />,
    },
    {
        path: 'add',
        element: <AddComponent />
    },
    {
        path: 'videos',
        element: <VideosComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/extras/empty-three/add" />
    },
    {
        path: 'edit/:id',
        element: <AddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/extras/empty-three/add" />
    },
];