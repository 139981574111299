import React, { useRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog, Slide } from "@mui/material";
import { BulbTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useWidth } from "../../../../libs/hooks";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { AbcRounded } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CameraComponentPropsI {
  open: boolean;
  handleClose: () => void;
  setQr: (fotoSiglas: string) => void;
}

export const ScanBarCodeComponent = ({
  open,
  handleClose,
  setQr,
}: CameraComponentPropsI) => {
  const width = useWidth();
  const openId = useRef<number>(1);
  const [torch, setTorch] = useState(false);

  return (
    <Dialog
      keepMounted={false}
      key={openId.current}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        p: 0,
        m: 0,
        bgcolor: "#271080",
        background: "#271080",
        height: "100%",
        minHeight: "100%",
      }}
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1,
          background: "#271080",
          height: "100vh",
        }}
      >
        <Button
          style={{
            position: "absolute",
            top: 1,
            right: 1,
            zIndex: 2,
            border:"1px solid white",
            borderRadius:0,
          }}
          size="large"
          onClick={handleClose}
          type="primary"
          // shape="circle"
          danger
          icon={<CloseCircleOutlined />}
        />
        <Button
          style={{
            position: "absolute",
            top: 60,
            right: 1,
            zIndex: 2,
            border:"1px solid white",
            borderRadius:0,
          }}
          size="large"
          onClick={() => setTorch(!torch)}
          type="primary"
          // shape="circle"
          icon={<BulbTwoTone twoToneColor={torch ? "#FFD700" : "#FF0000"} />}
        />
        <div
          style={{
            marginTop: width === "xs" ? "50%" : width === "sm" ? "5%" : "0%",
            border: "5px solid red",
            // width: width === "xs" ? "100%" : "50%",
            // position: "relative",
            // left: width === "xs" ? "0%" : "25%",
          }}
        >
          <BarcodeScannerComponent
            torch={torch}
            delay={100}
            videoConstraints={{
              facingMode: "environment",
              frameRate: 40,
              width: { ideal: 500 },
              height: { ideal: 800 },
              aspectRatio: 1.8,
              zoom: 2,
              autofocus: "continuous",
            }}
            onUpdate={(err, result) => {
              if (result) {
                const elCode: any = result["text"];
                setQr(elCode);
                handleClose();
              }
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
