import { useCallback, useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import FondoDashboard from "../../images/bg-dashboard.jpg";
import { useRouterStore } from "../../../libs/stores";
import {
  ClientComponent,
  ClientAdministratorComponent,
  AdministratorComponent,
} from "./components";

const options = [
  { value: "hoy", label: "Hoy" },
  { value: "semana", label: "Esta Semana" },
  { value: "mes", label: "Este Mes" },
  { value: "3meses", label: "Últimos 3 Meses" },
];
export const Dashboard = () => {
  const navigate = useNavigate();
  const typeDashboardCode = useRouterStore((state) => state.typeDashboardCode);

  const [data, setData] = useState<any>({
    total: 0,
    equipments: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), { variant: "error" });
      console.log(err);
    }
  };

  const handleLoading = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        backgroundImage: `url(${FondoDashboard})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      {typeDashboardCode === "DASHBOARD_CLIENT" ? (
        <ClientComponent options={options} />
      ) : null}
      {typeDashboardCode === "DASHBOARD_CLIENT_ADMINISTRATOR" ? (
        <ClientAdministratorComponent options={options} />
      ) : null}
      {typeDashboardCode === "DASHBOARD_ADMINISTRATOR" ? (
        <AdministratorComponent options={options} />
      ) : null}
    </div>
  );
};
