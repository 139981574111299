import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import { Button, QRCode, Tooltip as TooltipAntd } from "antd";
import { TransitionProps } from "@mui/material/transitions";
import {
  DownCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  PrinterOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

import { encryptHelper } from "../../../../libs/helpers";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import logoPng from "../../../../images/logo.svg";
import { COLOR_PRIMARY, LINK_QR, STRING_KEY } from "../../../../libs/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogPropsI {
  open: boolean;
  onClose: () => void;
  link: string;
  name: string;
  code: string;
}

const MIN_SIZE = 100;
const MAX_SIZE = 400;

interface ButtonQrPropsI {
  item: any;
  concatLink:
    | "workflow-empty/"
    | "workflow-full/"
    | "container/"
    | "workflow-checkpoint/";
}

export const ButtonQr = ({ item, concatLink }: ButtonQrPropsI) => {
  const [open, setOpen] = useState(false);
  const [linkQr, setLinkQr] = useState<string>("");
  // const [continuoDialog, setContinuoDialog] = useState(false);

  // useEffect(() => {
  //   if (continuoDialog) {
  //     setContinuoDialog(false);
  //     setOpen(true);
  //   }
  // }, [continuoDialog]);

  const handleGenerateQr = React.useCallback(() => {
    const encrypted = encryptHelper(STRING_KEY + item.id.toString());
    setLinkQr(`${LINK_QR}${concatLink}${encrypted}`);
    setOpen(true);
  }, [item.id, concatLink]);

  return (
    <>
      <TooltipAntd title="Generar QR">
        <Button
          type="dashed"
          size="small"
          onClick={handleGenerateQr}
          icon={<QrcodeOutlined />}
        />
      </TooltipAntd>
      {open && (
        <DialogQr
          open={open}
          onClose={() => setOpen(false)}
          link={linkQr}
          name={item.name}
          code={item.code}
        />
      )}
    </>
  );
};

const DialogQr: React.FC<DialogPropsI> = ({
  open,
  onClose,
  link,
  name,
  code,
}) => {
  const [size, setSize] = useState<number>(200);
  const componentRef = useRef<HTMLDivElement>(null);

  const downloadQRCode = React.useCallback(async () => {
    if (componentRef.current) {
      const canvas = await html2canvas(componentRef.current);
      const link = document.createElement("a");
      link.download = `QRCode_${code}.png`;
      link.href = canvas.toDataURL();
      link.click();
    }
  }, [code]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const adjustSize = (delta: number) => {
    setSize((prevSize) =>
      Math.min(Math.max(prevSize + delta, MIN_SIZE), MAX_SIZE)
    );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>
        <Chip label="Generador QR" color="primary" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{ display: "grid", placeContent: "center" }}>
            <div ref={componentRef} style={{ textAlign: "center" }}>
              <QRCode
                value={link}
                bgColor="#fff"
                size={size}
                iconSize={size / 4}
                icon={logoPng}
                style={{
                  borderRadius: "5px 5px 0 0",
                  border: `1px solid ${COLOR_PRIMARY}`,
                }}
              />
              <Box
                sx={{
                  border: `1px solid ${COLOR_PRIMARY}`,
                  borderRadius: "0 0 5px 5px",
                  backgroundImage:
                    "linear-gradient(to bottom, #00255e, #a6a6a6, #ffffff)",
                  width: size,
                  p: 1,
                  textAlign: "center",
                }}
              >
                {/* <Typography variant="button" sx={{ fontWeight: "bold", color: "white", fontSize: 12 }}>
                  {name} <br />
                  COD. ACTIVO {code}
                </Typography> */}
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: 10,
                    textWrap: "wrap",
                  }}
                >
                  Puce Plataforma Unificada para Control de Exportación
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "grid", placeContent: "center", mt: 2 }}
          >
            <Button.Group>
              <Button
                onClick={() => adjustSize(-10)}
                disabled={size <= MIN_SIZE}
                icon={<MinusOutlined />}
              />
              <Button
                type="primary"
                onClick={downloadQRCode}
                icon={<DownCircleOutlined />}
              >
                Descargar
              </Button>
              <Button
                type="primary"
                onClick={handlePrint}
                icon={<PrinterOutlined />}
              >
                Imprimir
              </Button>
              <Button
                onClick={() => adjustSize(10)}
                disabled={size >= MAX_SIZE}
                icon={<PlusOutlined />}
              />
            </Button.Group>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} danger>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
