import { useQuery } from "@tanstack/react-query";
import { workflowContainerVideosApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsWorkflowContainerVideosI,
    WorkflowContainerVideosI,
    ObjPostI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getWorkflowContainerVideos = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsWorkflowContainerVideosI):Promise<ServiceResponseI<DataI<WorkflowContainerVideosI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await workflowContainerVideosApi.get<ServiceResponseI<DataI<WorkflowContainerVideosI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const getAllWorkflowContainerVideos = async ():Promise<ServiceResponseI<WorkflowContainerVideosI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await workflowContainerVideosApi.get<ServiceResponseI<WorkflowContainerVideosI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowContainerVideosPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerVideosApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken,  "Content-Type": "multipart/form-data", } });
        return data;
    } catch (err: any) {
        if (err.code === "ERR_NETWORK") {
          return {
            data: {
              id: 0,
              alert: "Se guardará y notificará, cuando haya conexión a Internet",
              variant: "warning",
            },
            res: {} as any,
            req: {} as any,
          };
        }
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
          console.error(obj.statusText);
          LogoutSystem();
        }
        throw new Error((obj?.data as Error).message);
      }
}

export const workflowContainerVideosPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerVideosApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

// export const workflowContainerVideosById = async (id: number): Promise<ServiceResponseI<WorkflowContainerVideosI>> => {
//     try {
//         const validateToken = ValidateCurrentToken();
//         if (!validateToken) throw new Error('TOKEN INVALIDO');
//         const { data } = await workflowContainerVideosApi.get<ServiceResponseI<WorkflowContainerVideosI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
//         return data;
//     } catch (err) {
//         const obj = (err as AxiosError).response;
//         if (obj?.status === 401) {
//             console.error(obj.statusText);
//             LogoutSystem();
//         }
//         throw new Error((obj?.data as Error).message)
//     }
// }

export const workflowContainerVideosDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowContainerVideosApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useWorkflowContainerVideos = ({
    sorting,
    pagination,
    globalFilter,
}:PropsWorkflowContainerVideosI)=>{

    
    const workflowContainerVideosQuery = useQuery(
        ["workflowContainerVideos", { pagination, sorting, globalFilter }],
        ()=>getWorkflowContainerVideos({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        workflowContainerVideosQuery,
    };
}