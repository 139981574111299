import { Paper } from "@mui/material";
import { NAVBARSUB_HEIGHT } from "../../constants";

interface Props {
  children: JSX.Element;
}

export const NavbarSub = (props: Props) => {
  return (
    <Paper
      square
      sx={{
        height: Number(NAVBARSUB_HEIGHT),
        maxHeight: Number(NAVBARSUB_HEIGHT),
        padding: "4px 3px 3px 3px",
        position: "relative",
        left: -1,
      }}
      variant="outlined"
    >
      {props.children}
    </Paper>
  );
};
