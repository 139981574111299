import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog, Slide } from "@mui/material";
import { Button as ButtonAntd, ConfigProvider, message, Space } from "antd";
import {
  BulbTwoTone,
  CameraOutlined,
  CloseCircleOutlined,
  InteractionTwoTone,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { useRouterStore } from "../../../../libs/stores";

const d = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CameraComponentPropsI {
  open: boolean;
  handleClose: () => void;
  setFoto: (fotoSiglas: string) => void;
}

export const CameraComponentExporBananas = ({
  open,
  handleClose,
  setFoto,
}: CameraComponentPropsI) => {
  const camera = useRef<any>(null);
  const openId = useRef<number>(1);
  const photoWidth = useRouterStore((state) => state.photoWidth);
  const photoHeight = useRouterStore((state) => state.photoHeight);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  const [isChangingCamera, setChangingCamera] = useState<boolean>(false);
  const [continuoChange, setContinuoChange] = useState<boolean>(false);
  const [mirror, setMirror] = useState(false);
  const [videoConstraints, setVideoConstraints] = useState<any>({
    deviceId: activeDeviceId,
    facingMode: "environment",
    autoFocus: "continuous",
    whiteBalance: "continuous",
    frameRate: 30,
    zoom: 0,
    focusDepth: 0,
    width: photoWidth,
    height: photoHeight,
    // width: { ideal: 1280 },
    // height: { ideal: 1000 },
    torch: false,
  } as unknown as any);
  const [isTorchOn, setIsTorchOn] = useState(false);

  useLayoutEffect(() => {
    handleGetImages();
  }, []);
  useEffect(() => {
    // handleGetImages();
    console.log("ready", ready);
    return () => {
      camera.current! = null;
      setNumberOfCameras(0);
      setActiveDeviceId(undefined);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (continuoChange) {
      setContinuoChange(false);
      setChangingCamera(false);
    }
  }, [continuoChange]);

  useEffect(() => {
    if (!open) {
      // Increment id each time modal closes
      openId.current = openId.current + 1;
    }
  }, [open]);

  const handleGetImages = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((i) => i.kind == "videoinput");
    setNumberOfCameras(videoDevices.length);
    setReady(true);
  };

  const takeFoto = () => {
    try {
      if (camera === null) return;
      if (camera.current === null) return;
      setLoading(true);
      const photo = camera.current.getScreenshot();
      if (photo === null) {
        setLoading(false);
        return;
      }
      setFoto(photo);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  // const handleTorch = () => {
  //   if (videoConstraints.facingMode === "user") return;
  //   message.info(videoConstraints.torch ? "Turn Off Flash" : "Trun On Flash");
  //   setChangingCamera(true);
  //   setVideoConstraints((constraints: any) => ({
  //     ...constraints,
  //     torch: !constraints.torch,
  //   }));
  //   setContinuoChange(true);
  // };

  const changeFacingMode = () => {
    message.info("Cambiando el modo de cámara");
    setChangingCamera(true);
    if (videoConstraints.facingMode === "environment") {
      setMirror(true);
    } else {
      setMirror(false);
    }
    setVideoConstraints((constraints: any) => ({
      ...constraints,
      facingMode: constraints.facingMode === "user" ? "environment" : "user",
    }));
    setContinuoChange(true);
  };

  // const handleZoomIn = () => {
  //   const num_ = elZoom + 1;
  //   setElZoom(num_);
  //   setVideoConstraints((constraints: any) => {
  //     return {
  //       ...constraints,
  //       zoom: num_,
  //     };
  //   });
  //   setContinuoChange(true);
  // };

  // const handleZoomOut = () => {
  //   if (elZoom === 0) return;
  //   const num_ = elZoom - 1;
  //   const numZoom = Math.max(0, num_);
  //   setElZoom(numZoom);
  //   setVideoConstraints((constraints: any) => {
  //     return {
  //       ...constraints,
  //       zoom: numZoom,
  //     };
  //   });
  //   setContinuoChange(true);
  // };

  const handleZoomChange = async (increment: number) => {
    const [track] =
      (camera.current?.video.srcObject as MediaStream)?.getVideoTracks() || [];

    if (track && track.getCapabilities().zoom) {
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();
      const newZoom = Math.min(
        capabilities.zoom!.max,
        Math.max(capabilities.zoom!.min, (settings.zoom || 0) + increment)
      );

      await track.applyConstraints({ advanced: [{ zoom: newZoom }] });
    } else {
      message.error("Zoom no soportado en este dispositivo");
    }
  };

  const handleZoomIn = () => handleZoomChange(1);
  const handleZoomOut = () => handleZoomChange(-1);

  const handleTorchToggle = async () => {
    const [track] =
      (camera.current?.video.srcObject as MediaStream)?.getVideoTracks() || [];

    if (track && track.getCapabilities().torch) {
      const newTorchState = !isTorchOn;

      await track.applyConstraints({ advanced: [{ torch: newTorchState }] });
      setIsTorchOn(newTorchState); // Actualiza solo el estado del torch

      message.info(newTorchState ? "Linterna encendida" : "Linterna apagada");
    } else {
      message.error("Torch no soportado en este dispositivo");
    }
  };

  return (
    <Dialog
      keepMounted={false}
      key={openId.current}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        p: 0,
        m: 0,
        bgcolor: "#271080",
      }}
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1,
          background: "#271080",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {isChangingCamera ? null : (
          <Webcam
            height="100%"
            videoConstraints={videoConstraints}
            forceScreenshotSourceSize
            audio={false}
            mirrored={mirror}
            ref={camera}
            // screenshotQuality={1}
            style={{ minHeight: "100%" }}
            screenshotFormat="image/webp"
          />
        )}
      </div>
      <ButtonAntd
        style={{
          position: "absolute",
          top: 1,
          right: 1,
          zIndex: 2,
        }}
        size="large"
        onClick={handleClose}
        type="primary"
        shape="circle"
        danger
        icon={<CloseCircleOutlined />}
      />
      <ButtonAntd
        style={{
          position: "absolute",
          bottom: 15,
          left: "calc(50vw - 87px)",
          // left: width / 2 - 87,
          zIndex: 2,
        }}
        size="large"
        disabled={numberOfCameras < 2}
        onClick={changeFacingMode}
        type="dashed"
        shape="circle"
        icon={
          <InteractionTwoTone
            twoToneColor={numberOfCameras < 2 ? "#FFD700" : "#FF0000"}
          />
        }
      />
      <ButtonAntd
        style={{
          position: "absolute",
          top: 1,
          left: 1,
          zIndex: 2,
        }}
        size="large"
        onClick={handleTorchToggle}
        type="dashed"
        shape="circle"
        icon={<BulbTwoTone twoToneColor={isTorchOn ? "#FFD700" : "#FF0000"} />}
      />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#271080",
          },
        }}
      >
        <ButtonAntd
          style={{
            position: "absolute",
            bottom: 10,
            left: "calc(50vw - 33px)",
            // width / 2 - 33,
            width: 70,
            height: 70,
            zIndex: 2,
          }}
          loading={loading}
          disabled={loading || !ready}
          size="large"
          onClick={takeFoto}
          type="primary"
          shape="circle"
          icon={<CameraOutlined />}
        />
      </ConfigProvider>
      <div
        style={{
          position: "absolute",
          top: 150,
          right: 2,
          zIndex: 2,
        }}
      >
        <Space.Compact style={{ width: "100%" }} direction="vertical">
          <ButtonAntd
            size="large"
            onClick={handleZoomIn}
            type="primary"
            shape="circle"
            loading={continuoChange}
            icon={<ZoomInOutlined />}
          />
          <ButtonAntd
            size="large"
            onClick={handleZoomOut}
            type="primary"
            shape="circle"
            loading={continuoChange}
            icon={<ZoomOutOutlined />}
          />
        </Space.Compact>
      </div>
    </Dialog>
  );
};
