import React from 'react'

export const ClientAdministratorComponent = ({options}:{options:any[]}) => {
  return (
    <div>
      
    </div>
  )
}

