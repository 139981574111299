import loadingPicture from "../images/loading-expo.gif"

export const LoadingPage = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeContent: "center",
                background:"rgba(202, 199, 199, 0.34)",
            }}>
                <img src={loadingPicture} width="100%" style={{width:500, height:500, borderRadius:5, background:'white',}}/>
        </div>
    )
}