import { useCallback, useEffect, useState } from "react";

export const useScan = () => {
  const [showScanQr, setShowScanQr] = useState(false);
  const [openScanQr, setOpenScanQr] = useState(false);
  const [continuoScanQr, setContinuoScanQr] = useState(false);
  const [showScanBarCode, setShowScanBarCode] = useState(false);
  const [openScanBarCode, setOpenScanBarCode] = useState(false);
  const [continuoScanBarCode, setContinuoScanBarCode] = useState(false);

  useEffect(() => {
    if (continuoScanQr) {
      setContinuoScanQr(false);
      setOpenScanQr(true);
    }
  }, [continuoScanQr]);

  useEffect(() => {
    if (continuoScanBarCode) {
      setContinuoScanBarCode(false);
      setOpenScanBarCode(true);
    }
  }, [continuoScanBarCode]);

  const handleCloseQr = useCallback(() => {
    setOpenScanQr(false);
    setShowScanQr(false);
  }, []);

  const handleCloseBarCode = useCallback(() => {
    setOpenScanBarCode(false);
    setShowScanBarCode(false);
  }, []);

  const handleOpenQr = () => {
    setShowScanQr(true);
    setContinuoScanQr(true);
  };

  const handleOpenBarCode = () => {
    setShowScanBarCode(true);
    setContinuoScanBarCode(true);
  };
  return {
    showScanQr,
    openScanQr,
    showScanBarCode,
    openScanBarCode,
    handleCloseQr,
    handleCloseBarCode,
    handleOpenQr,
    handleOpenBarCode,
  };
};
