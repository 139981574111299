import React from "react";
import {
  AppBar as MuiAppBar,
  Chip,
  Button,
  IconButton,
  Toolbar,
} from "@mui/material";
import {
  ArrowCircleLeftSharp,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import "../../css/appbar.css";
import logoExpo from "../../images/logo.png";
import { useRouterStore } from "../../../libs/stores";
import {
  COLOR_PRIMARY,
} from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";
import { useNavigate } from "react-router-dom";
import { UserAvatar } from "../../../libs/shared/components";

export const drawerWidth = 160;

const AppBarElement = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, small }) => ({
  height: `57px`,
  minHeight: `57px`,
  maxHeight: `57px`,
  zIndex: 1001,
  background: COLOR_PRIMARY,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: small === "true" ? 0 : drawerWidth,
    width: small === "true" ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({ authTokens, handleDrawerOpen, open }) => {
  const width = useWidth();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <AppBarElement
        position="fixed"
        open={open}
        small={width === "xs" ? true : false}
      >
        <Toolbar className="toolbar">
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                borderRadius: 2,
                border:"1px solid gainsboro",
                height: 32,
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Button
              onClick={() => navigate(-1)}
              size="large"
              color="inherit"
              variant="outlined"
              sx={{
                position: "relative",
                left: open ? "-19px" : width === "xs" ? "-30px" : "-2px",
                marginRight:"-50px",
              }}
            >
              <ArrowCircleLeftSharp />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img src={logoExpo} alt="EXPOBANANA" className="logo" />
            <ElChip/>
          </div>
         
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              right: "-10px",
            }}
          >
            <UserAvatar />
          </div>
        </Toolbar>
      </AppBarElement>
    </React.Fragment>
  );
};

export const ElChip = ()=>{
  const section = useRouterStore((state) => state.section);
  if(!section) return null;
  return(
    <Chip title={section} color="secondary" size="small" label={section} sx={{fontWeight:"bold"}}/>
  )
}
