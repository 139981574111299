import { useQuery } from "@tanstack/react-query";
import { workflowTruckApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsWorkflowTruckI,
    WorkflowTruckI,
    ObjPostI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getWorkflowTrucks = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsWorkflowTruckI):Promise<ServiceResponseI<DataI<WorkflowTruckI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await workflowTruckApi.get<ServiceResponseI<DataI<WorkflowTruckI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const getAllWorkflowTrucks = async ():Promise<ServiceResponseI<WorkflowTruckI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await workflowTruckApi.get<ServiceResponseI<WorkflowTruckI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowTruckPost = async<T>(getForms: T): Promise<ServiceResponseI<ObjPostI> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowTruckApi.post<ServiceResponseI<ObjPostI>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err:any) {
        if (err.code === "ERR_NETWORK") {
            return {
              data: {
                id: 0,
                alert: "Se guardará y notificará, cuando haya conexión a Internet",
                variant:"warning"
              },
              res:{} as any,
              req:{} as any
            };
          }
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowTruckPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowTruckApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowTruckById = async (id: number): Promise<ServiceResponseI<WorkflowTruckI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowTruckApi.get<ServiceResponseI<WorkflowTruckI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const workflowTruckCount = async (
  type="hoy"
): Promise<ServiceResponseI<{ total: number }>> => {
  try {
    const params = new URLSearchParams();

    type && params.append("type", type.toString());

    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowTruckApi.get<
      ServiceResponseI<{ total: number }>
    >(`/count`, { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowTruckDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await workflowTruckApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useWorkflowTrucks = ({
    sorting,
    pagination,
    globalFilter,
}:PropsWorkflowTruckI)=>{

    
    const workflowTrucksQuery = useQuery(
        ["workflowTrucks", { pagination, sorting, globalFilter }],
        ()=>getWorkflowTrucks({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        workflowTrucksQuery,
    };
}

export const generateWfTruckPdfReportbyId = async (
    id: number
  ): Promise<ServiceResponseI<any>> => {
    try {
    //   const validateToken = ValidateCurrentToken();
    //   if (!validateToken) throw new Error("TOKEN INVALIDO");
  
      const { data } = await workflowTruckApi.get<ServiceResponseI<any>>(
        `/generate-pdf-report/${id}`,
        // { headers: { Authorization: validateToken } }
      );
  
      return data;
    } catch (err) {
      const obj = (err as AxiosError).response;
      if (obj?.status === 401) {
        console.error(obj.statusText);
        LogoutSystem();
      }
      throw new Error((obj?.data as Error).message);
    }
  };
  
  export const workflowTruckSendReportById = async <T>(
    getForms: T
  ): Promise<ServiceResponseI<string>> => {
    try {
      const validateToken = ValidateCurrentToken();
      if (!validateToken) throw new Error("TOKEN INVALIDO");
      const { data } = await workflowTruckApi.post<
        ServiceResponseI<string>
      >(`/send-report-by-workflow-id`, getForms, {
        headers: { Authorization: validateToken },
      });
      return data;
    } catch (err) {
      const obj = (err as AxiosError).response;
      if (obj?.status === 401) {
        LogoutSystem();
      }
      throw new Error((obj?.data as Error).message);
    }
  };