import {
  BarcodeOutlined,
  ContainerOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Button, Popover, Space } from "antd";
import React, { Fragment } from "react";
import { ScanQrComponent } from "./scan-qr";
import { ScanBarCodeComponent } from "./scan-bar-code";

interface ScanComponentPropsI {
  handleOpenQr: () => void;
  handleOpenBarCode: () => void;
  width: string;
  showScanQr: boolean;
  handleSetQr: (val: string) => void;
  openScanQr: boolean;
  handleCloseQr: () => void;
  showScanBarCode: boolean;
  openScanBarCode: boolean;
  handleCloseBarCode: () => void;
}

export const ScanComponent = ({
  handleOpenQr,
  handleOpenBarCode,
  width,
  showScanQr,
  handleSetQr,
  openScanQr,
  handleCloseQr,
  showScanBarCode,
  openScanBarCode,
  handleCloseBarCode,
}: ScanComponentPropsI) => {
  return (
    <Fragment>
      {/* <Popover
        title="Tipo de Escaneo"
        content={
          <div>
            <Space
              direction="vertical"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Button
                icon={<QrcodeOutlined />}
                onClick={handleOpenQr}
                style={{ width: "100%" }}
              >
                QR
              </Button>
              <Button
                icon={<BarcodeOutlined />}
                onClick={handleOpenBarCode}
                style={{ width: "100%" }}
              >
                BARRAS
              </Button>
            </Space>
          </div>
        }
        trigger="click"
      > */}
        <Button
          icon={<BarcodeOutlined />}
          onClick={handleOpenBarCode}
          size={width === "xs" ? "middle" : "large"}
        //   style={{
        //     maxHeight: width === "xs" ? "36.7px" : "37.8px",
        //     minHeight: width === "xs" ? "36.7px" : "37.8px",
        //     height: width === "xs" ? "36.7px" : "37.8px",
        //     width: "40px",
        //   }}
        />
      {/* </Popover> */}
      {showScanQr && (
        <ScanQrComponent
          setQr={handleSetQr}
          open={openScanQr}
          handleClose={handleCloseQr}
        />
      )}
      {showScanBarCode && (
        <ScanBarCodeComponent
          setQr={handleSetQr}
          open={openScanBarCode}
          handleClose={handleCloseBarCode}
        />
      )}
    </Fragment>
  );
};
