import { useQuery } from "@tanstack/react-query";
import { workflowContainerCheckPointApi } from "./axios";
import {
  DataI,
  ServiceResponseI,
  PropsWorkflowContainerCheckPointI,
  WorkflowContainerCheckPointI,
  ObjPostI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";

const getWorkflowContainerCheckPoints = async ({
  pagination,
  sorting,
  globalFilter,
  producer,
  farm,
  client,
}: PropsWorkflowContainerCheckPointI): Promise<
  ServiceResponseI<DataI<WorkflowContainerCheckPointI>>
> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));
    client && params.append("client", client.toString());
    producer && params.append("producer", producer.toString());
    farm && params.append("farm", farm.toString());
    
    const { data } = await workflowContainerCheckPointApi.get<
      ServiceResponseI<DataI<WorkflowContainerCheckPointI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    console.log(err);
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }

    throw new Error((obj?.data as Error).message);
  }
};

export const getAllWorkflowContainerCheckPoints = async (): Promise<
  ServiceResponseI<WorkflowContainerCheckPointI[]>
> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerCheckPointApi.get<
      ServiceResponseI<WorkflowContainerCheckPointI[]>
    >(`/all-no-pagination`, { headers: { Authorization: validateToken } });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerCheckPointPost = async <T>(
  getForms: T
): Promise<ServiceResponseI<ObjPostI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.post<
      ServiceResponseI<ObjPostI>
    >("", getForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerCheckPointPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.put<
      ServiceResponseI<string>
    >(`/${id}`, updateForms, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerCheckPointById = async (
  id: number
): Promise<ServiceResponseI<WorkflowContainerCheckPointI>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.get<
      ServiceResponseI<WorkflowContainerCheckPointI>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerCheckPointDelete = async (
  id: number
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.delete<
      ServiceResponseI<string>
    >(`/${id}`, { headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowContainerCheckPointCount = async (
  type="hoy"
): Promise<ServiceResponseI<{ total: number }>> => {
  try {
    const params = new URLSearchParams();

    type && params.append("type", type.toString());

    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.get<
      ServiceResponseI<{ total: number }>
    >(`/count`, { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useWorkflowContainerCheckPoints = ({
  sorting,
  pagination,
  globalFilter,
  producer,
  farm,
  client
}: PropsWorkflowContainerCheckPointI) => {
  const workflowContainerCheckPointsQuery = useQuery(
    ["workflowContainerCheckPoints", { pagination, sorting, globalFilter, client, producer, farm  }],
    () =>
      getWorkflowContainerCheckPoints({ pagination, sorting, globalFilter, client, producer, farm  }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    workflowContainerCheckPointsQuery,
  };
};

export const generateWfCheckpointPdfReportbyId = async (
  id: number
): Promise<ServiceResponseI<any>> => {
  try {
    // const validateToken = ValidateCurrentToken();
    // if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await workflowContainerCheckPointApi.get<
      ServiceResponseI<any>
    >(`/generate-pdf-report/${id}`, 
      // {headers: { Authorization: validateToken },}
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const workflowCheckpointSendReportById = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await workflowContainerCheckPointApi.post<
      ServiceResponseI<string>
    >(`/send-report-by-workflow-id`, getForms, {
      headers: { Authorization: validateToken },
    });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};
