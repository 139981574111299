import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useWidth } from "../../../../../libs/hooks";
import {
  Badge,
  message,
  Select,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import { CardComponent } from "./card-component";
import { TableComponent } from "./table-component";
import {
  workflowContainerCheckPointCount,
  workflowContainerEmptyCount,
  workflowContainerFullCount,
  workflowTruckCount,
} from "../../../../../libs/apis";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

export const AdministratorComponent = ({ options }: { options: any[] }) => {
  const width = useWidth();
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const [valueEmpty, setValueEmpty] = useState("Hoy");
  const [valueFull, setValueFull] = useState("Hoy");
  const [valueCheck, setValueCheck] = useState("Hoy");
  const [valueTruck, setValueTruck] = useState("Hoy");
  const [totalEmpty, setTotalEmpty] = useState(0);
  const [totalFull, setTotalFull] = useState(0);
  const [totalCheck, setTotalCheck] = useState(0);
  const [totalTruck, setTotalTruck] = useState(0);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    try {
      handleGetEmpty("hoy");
      handleGetFull("hoy");
      handleGetCheckPoint("hoy");
      handleGetTruck("hoy");
    } catch (err: any) {
      message.error(err.toString());
    }
  };
  const handleUpdateEmpty = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueEmpty(fill.label);
      handleGetEmpty(fill.value);
    },
    [valueEmpty]
  );

  const handleUpdateFull = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueFull(fill.label);
      handleGetFull(fill.value);
    },
    [valueFull]
  );

  const handleUpdateCheckPoint = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueCheck(fill.label);
      handleGetCheckPoint(fill.value);
    },
    [valueCheck]
  );

  const handleUpdateTruck = useCallback(
    (val: string) => {
      const fill = options.find((el) => el.value === val);
      setValueTruck(fill.label);
      handleGetTruck(fill.value);
    },
    [valueTruck]
  );

  const handleGetEmpty = async (value: string) => {
    try {
      const { data: dataEmpty } = await workflowContainerEmptyCount(value);
      if (!dataEmpty) return;
      setTotalEmpty(dataEmpty.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetFull = async (value: string) => {
    try {
      const { data: dataFull } = await workflowContainerFullCount(value);
      if (!dataFull) return;
      setTotalFull(dataFull.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetCheckPoint = async (value: string) => {
    try {
      const { data: dataCheckPoint } = await workflowContainerCheckPointCount(
        value
      );
      if (!dataCheckPoint) return;
      setTotalCheck(dataCheckPoint.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const handleGetTruck = async (value: string) => {
    try {
      const { data: dataTruck } = await workflowTruckCount(value);
      if (!dataTruck) return;
      setTotalTruck(dataTruck.total);
    } catch (err: any) {
      message.error(err.toString());
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Usuario",
      dataIndex: "name",
      showSorterTooltip: { target: "full-header" },
      // filters: [
      //   {
      //     text: 'Joe',
      //     value: 'Joe',
      //   },
      //   {
      //     text: 'Jim',
      //     value: 'Jim',
      //   },
      // ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Hora",
      dataIndex: "age",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Tipo Contenedor",
      dataIndex: "address",
      filters: [
        {
          text: "Vacío",
          value: "London",
        },
        {
          text: "LLeno",
          value: "New York",
        },
        {
          text: "CheckPoint",
          value: "New York",
        },
      ],
      onFilter: (value, record) =>
        record.address.indexOf(value as string) === 0,
    },
  ];

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "Contenedor Vacío",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "Contenedor Lleno",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Contenedor Vacío",
    },
    {
      key: "4",
      name: "Jim Red",
      age: 32,
      address: "CheckPoint",
    },
  ];

  return (
    <Box
      sx={{
        p: 1,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Contendedores Vacíos"
            quantity={totalEmpty}
            handleUpdate={handleUpdateEmpty}
            options={options}
            color="tomato"
            value={valueEmpty}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Contendedores Full"
            quantity={totalFull}
            handleUpdate={handleUpdateFull}
            options={options}
            color="orange"
            value={valueFull}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="CheckPoint"
            quantity={totalCheck}
            handleUpdate={handleUpdateCheckPoint}
            options={options}
            color="green"
            value={valueCheck}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <CardComponent
            width={width}
            title="Camión"
            quantity={totalTruck}
            handleUpdate={handleUpdateTruck}
            options={options}
            color="blue"
            value={valueTruck}
          />
        </Grid>
        <Grid item xs={12}>
          <TableComponent />
        </Grid>
      </Grid>
    </Box>
  );
};
